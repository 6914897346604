import ItodoImage from "../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import { NavLink } from "react-router-dom";

function PackagePage5() {
  useEffect(() => {
    loadScript("js/custom.js");
  }, []);

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer " style={{backgroundColor:"black"}}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/social1.jpg"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Social Media Optimization Packages
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <h1 className="sx-title d-flex justify-content-center" style={{ color: "#fdcb32" }}>SMO Packages</h1>
                <div className="sx-head-l-title ">
                  <h3 style={{ color: "#fdcb32" }}>
                  Tailored SMO packages to boost your social media presence and engagement.
                  </h3>
                </div>
              </div>
              {/* TITLE END */}

              {/* Packages Container */}
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Basic <br /> SMO  Package</h2>
                    <h1 className="d-flex justify-content-center">$220/Per Month</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
  <li><strong>Time Frame: 6</strong></li>
  <li><strong>Dedicated Account Manager</strong></li>
  <li><strong>Content Calendar</strong></li>
  <li><strong>Campaign Monitoring</strong></li>
<h5>Facebook</h5>
  {/* <li><strong>Facebook</strong></li> */}
  <li><strong>Facebook A/C & Fan Page Set Up</strong></li>
  <li><strong>Posts Per Month: 10</strong></li>
  <li><strong>Cover Photos</strong></li>
  <li><strong>Facebook Groups (Join & Network)</strong></li>
  <li><strong>New Likes Per Month: 50</strong></li>
  <h5>Instagram</h5>
  {/* <li><strong>Instagram</strong></li> */}
  <li><strong>Instagram A/C Set Up</strong></li>
  <li><strong>Posts Per Month: 5</strong></li>
  <li><strong>New Likes Per Month: 20</strong></li>
  <h5>Twitter</h5>
  {/* <li><strong>Twitter</strong></li> */}
  <li><strong>Twitter A/C Setup</strong></li>
  <li><strong>Posts Per Month: 10</strong></li>
  <li><strong>Background Photo</strong></li>
  <li><strong>New Followers Per Month: 20</strong></li>
  <h5>Linkedin</h5>
  {/* <li><strong>Linkedin</strong></li> */}
  <li><strong>Linkedin A/C Setup</strong></li>
  <li><strong>Posts Per Month: 5</strong></li>
  <li><strong>Cover Photo</strong></li>
  <li><strong>Linkedin Groups (Join & Network)</strong></li>
  <li><strong>New Followers Per Month: 5</strong></li>
  <h5>Pinterest</h5>
  {/* <li><strong>Pinterest</strong></li> */}
  <li><strong>Pinterest A/C Setup</strong></li>
  <li><strong>Pin Board Creation</strong></li>
  <li><strong>Pinboard Updates</strong></li>
  <h5>Youtube</h5>
  {/* <li><strong>YouTube</strong></li> */}
  <li><strong>YouTube Channel Creation (If Required)</strong></li>
  <li><strong>Video Content Optimization</strong></li>
  <li><strong>Video Title & Meta Description Optimization</strong></li>
  <li><strong>Video Submissions: 5</strong></li>
  <li><strong>Video Sharing On Social Media</strong></li>

  <li><strong>Monthly Reports</strong></li>
  <li><strong>Monthly Activity Reports</strong></li>
  <li><strong>Google Social Analytics Reports</strong></li>
  <h5>Customer Support</h5>
  {/* <li><strong>Customer Support</strong></li> */}
  <li><strong>Email</strong></li>
  <li><strong>Phone</strong></li>
  <li><strong>Chat</strong></li>
</ul>
<NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>

                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Standard <br /> SMO  Package</h2>
                    <h1 className="d-flex justify-content-center">$340/Per Month</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
  <li><strong>Time Frame: 6</strong></li>
  <li><strong>Dedicated Account Manager</strong></li>
  <li><strong>Content Calendar</strong></li>
  <li><strong>Campaign Monitoring</strong></li>
<h5>Facebook</h5>
  {/* <li><strong>Facebook</strong></li> */}
  <li><strong>Facebook A/C & Fan Page Set Up</strong></li>
  <li><strong>Posts Per Month: 20</strong></li>
  <li><strong>Cover Photos</strong></li>
  <li><strong>Facebook Groups (Join & Network)</strong></li>
  <li><strong>New Likes Per Month: 100</strong></li>
<h5>Instagram</h5>
  {/* <li><strong>Instagram</strong></li> */}
  <li><strong>Instagram A/C Set Up</strong></li>
  <li><strong>Posts Per Month: 10</strong></li>
  <li><strong>New Likes Per Month: 30</strong></li>
<h5>Twitter</h5>
  {/* <li><strong>Twitter</strong></li> */}
  <li><strong>Twitter A/C Setup</strong></li>
  <li><strong>Posts Per Month: 20</strong></li>
  <li><strong>Background Photo</strong></li>
  <li><strong>New Followers Per Month: 30</strong></li>
<h5>Linkedin</h5>
  {/* <li><strong>Linkedin</strong></li> */}
  <li><strong>Linkedin A/C Setup</strong></li>
  <li><strong>Posts Per Month: 10</strong></li>
  <li><strong>Cover Photo</strong></li>
  <li><strong>Linkedin Groups (Join & Network)</strong></li>
  <li><strong>New Followers Per Month: 10</strong></li>
<h5>Pinterest</h5>
  {/* <li><strong>Pinterest</strong></li> */}
  <li><strong>Pinterest A/C Setup</strong></li>
  <li><strong>Pin Board Creation</strong></li>
  <li><strong>Pinboard Updates</strong></li>
<h5>Youtube</h5>
  {/* <li><strong>YouTube</strong></li> */}
  <li><strong>YouTube Channel Creation (If Required)</strong></li>
  <li><strong>Video Content Optimization</strong></li>
  <li><strong>Video Title & Meta Description Optimization</strong></li>
  <li><strong>Video Submissions: 10</strong></li>
  <li><strong>Video Sharing On Social Media</strong></li>

  <li><strong>Monthly Reports</strong></li>
  <li><strong>Monthly Activity Reports</strong></li>
  <li><strong>Google Social Analytics Reports</strong></li>
<h5>Customer Support</h5>
  {/* <li><strong>Customer Support</strong></li> */}
  <li><strong>Email</strong></li>
  <li><strong>Phone</strong></li>
  <li><strong>Chat</strong></li>
</ul>
<NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Professional <br /> SMO  Package</h2>
                    <h1 className="d-flex justify-content-center">$460/Per Month</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
  <li><strong>Time Frame: 6</strong></li>
  <li><strong>Dedicated Account Manager</strong></li>
  <li><strong>Content Calendar</strong></li>
  <li><strong>Campaign Monitoring</strong></li>
<h5>Facebook</h5>
  {/* <li><strong>Facebook</strong></li> */}
  <li><strong>Facebook A/C & Fan Page Set Up</strong></li>
  <li><strong>Posts Per Month: 30</strong></li>
  <li><strong>Cover Photos</strong></li>
  <li><strong>Facebook Groups (Join & Network)</strong></li>
  <li><strong>New Likes Per Month: 150</strong></li>
<h5>Instagram</h5>
  {/* <li><strong>Instagram</strong></li> */}
  <li><strong>Instagram A/C Set Up</strong></li>
  <li><strong>Posts Per Month: 15</strong></li>
  <li><strong>New Likes Per Month: 30</strong></li>
<h5>Twitter</h5>
  {/* <li><strong>Twitter</strong></li> */}
  <li><strong>Twitter A/C Setup</strong></li>
  <li><strong>Posts Per Month: 30</strong></li>
  <li><strong>Background Photo</strong></li>
  <li><strong>New Followers Per Month: 50</strong></li>
<h5>Linkedin</h5>
  {/* <li><strong>Linkedin</strong></li> */}
  <li><strong>Linkedin A/C Setup</strong></li>
  <li><strong>Posts Per Month: 15</strong></li>
  <li><strong>Cover Photo</strong></li>
  <li><strong>Linkedin Groups (Join & Network)</strong></li>
  <li><strong>New Followers Per Month: 20</strong></li>
<h5>Pinterest</h5>
  {/* <li><strong>Pinterest</strong></li> */}
  <li><strong>Pinterest A/C Setup</strong></li>
  <li><strong>Pin Board Creation</strong></li>
  <li><strong>Pinboard Updates</strong></li>
<h5>Youtube</h5>
  {/* <li><strong>YouTube</strong></li> */}
  <li><strong>YouTube Channel Creation (If Required)</strong></li>
  <li><strong>Video Content Optimization</strong></li>
  <li><strong>Video Title & Meta Description Optimization</strong></li>
  <li><strong>Video Submissions: 15</strong></li>
  <li><strong>Video Sharing On Social Media</strong></li>

  <li><strong>Monthly Reports</strong></li>
  <li><strong>Monthly Activity Reports</strong></li>
  <li><strong>Google Social Analytics Reports</strong></li>
<h5>Customer Support</h5>
  {/* <li><strong>Customer Support</strong></li> */}
  <li><strong>Email</strong></li>
  <li><strong>Phone</strong></li>
  <li><strong>Chat</strong></li>
</ul>
<NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackagePage5;
