import { useLocation } from "react-router-dom";
import Footer from "../app/sections/common/footer";
import Header1 from "../app/sections/common/header1";
import AppRoutes from "../routing/app-routes";
import { useEffect } from "react";

function RootLayout() {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const { pathname } = useLocation();
  return (
    <>
      <div className="page-wraper">
        {<Header1 />}

        <div className="page-content">
          <AppRoutes />
        </div>

        <Footer />
        {/* <!-- BUTTON TOP START --> */}

        <a href={pathname}>
          <button className="scroltop" onClick={handleScrollToTop()}>
            <span className="fa fa-angle-up  relative" id="btn-vibrate"></span>
          </button>
        </a>
      </div>
    </>
  );
}

export default RootLayout;
