import Banner from "../../sections/common/banner";
// import ItodoImage from "../../elements/itodo-img";
import Pricingbox from "./pricingBox";
import { bannerData } from "../../../globals/banner";
import { NavLink } from "react-router-dom";

function TeamPage() {
    return (
        <>
            <Banner _data={bannerData.team} />
<Pricingbox />
          

        </>
    )
}

export default TeamPage;