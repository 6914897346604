import ItodoImage from "../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import { NavLink } from "react-router-dom";

function PackagePage3() {
  useEffect(() => {
    loadScript("js/custom.js");
  }, []);

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer " style={{backgroundColor:"black"}}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/comweb.jpg"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                E-Commerce Website Packages
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <h1 className="sx-title d-flex justify-content-center" style={{ color: "#fdcb32" }}>E-Commerce Packages</h1>
                <div className="sx-head-l-title">
                  <h3 style={{ color: "#fdcb32" }}>
                    Choose the perfect plan for your website development needs.
                  </h3>
                </div>
              </div>
              {/* TITLE END */}

              {/* Packages Container */}
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">WordPress <br /> E-Commerce  Package</h2>
                    <h1 className="d-flex justify-content-center">$1200/-</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
  <li><strong>Custom Layout Design</strong></li>
  <li><strong>Theme Integration</strong></li>
  <li><strong>JQUERY Slider Home Page</strong></li>
  <li><strong>Search</strong></li>
  <li><strong>Advance Search</strong></li>
  <li><strong>Sign Up, Sign In, Forget Password</strong></li>
  <li><strong>My Account</strong></li>
  <li><strong>Wishlist</strong></li>
  <li><strong>Shopping Cart</strong></li>
  <li><strong>Product Listing</strong></li>
  <li><strong>Product Detail Page</strong></li>
  <li><strong>Product Comparison</strong></li>
  <li><strong>Multiple Product Image</strong></li>
  <li><strong>Real Zoom On Product Image</strong></li>
  <li><strong>Write A Review</strong></li>
  <li><strong>Email To Friend</strong></li>
  <li><strong>Share On Social Sites (Pinit, Fb, Twitter, LinkedIn)</strong></li>
  <li><strong>Similar Products</strong></li>
  <li><strong>Latest Arrivals</strong></li>
  <li><strong>Best Sellers</strong></li>
  <li><strong>Guest Checkout</strong></li>
  <li><strong>Single Page Checkout</strong></li>
  <li><strong>Discount Coupon</strong></li>
  <li><strong>Abandon Cart</strong></li>
  <li><strong>Payment Gateway Integration (PayPal)</strong></li>
  <li><strong>Shipping Methods</strong></li>
  <li><strong>Testimonials</strong></li>
  <li><strong>Newsletter</strong></li>
  <li><strong>FAQs</strong></li>
  <li><strong>Contact Us</strong></li>
  <li><strong>Sitemap</strong></li>
  <li><strong>Static HTML URLs</strong></li>
  <li><strong>Live Chat</strong></li>
  <li><strong>Customer Feedback</strong></li>
  <li><strong>Different Price For Public And Retails</strong></li>
  <li><strong>Multiple Currency Support</strong></li>
  <li><strong>Multiple Language Support</strong></li>
  <li><strong>Blog</strong></li>
</ul>
<NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">OpenCart <br /> E-Commerce  Package</h2>
                    <h1 className="d-flex justify-content-center">$1500/-</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
  <li><strong>Custom Layout Design</strong></li>
  <li><strong>Theme Integration</strong></li>
  <li><strong>JQUERY Slider Home Page</strong></li>
  <li><strong>Search</strong></li>
  <li><strong>Advance Search</strong></li>
  <li><strong>Sign Up, Sign In, Forget Password</strong></li>
  <li><strong>My Account</strong></li>
  <li><strong>Wishlist</strong></li>
  <li><strong>Shopping Cart</strong></li>
  <li><strong>Product Listing</strong></li>
  <li><strong>Product Detail Page</strong></li>
  <li><strong>Product Comparison</strong></li>
  <li><strong>Multiple Product Image</strong></li>
  <li><strong>Real Zoom On Product Image</strong></li>
  <li><strong>Write A Review</strong></li>
  <li><strong>Email To Friend</strong></li>
  <li><strong>Share On Social Sites (Pinit, Fb, Twitter, LinkedIn)</strong></li>
  <li><strong>Similar Products</strong></li>
  <li><strong>Latest Arrivals</strong></li>
  <li><strong>Best Sellers</strong></li>
  <li><strong>Guest Checkout</strong></li>
  <li><strong>Single Page Checkout</strong></li>
  <li><strong>Discount Coupon</strong></li>
  <li><strong>Abandon Cart</strong></li>
  <li><strong>Payment Gateway Integration (PayPal)</strong></li>
  <li><strong>Shipping Methods</strong></li>
  <li><strong>Testimonials</strong></li>
  <li><strong>Newsletter</strong></li>
  <li><strong>FAQs</strong></li>
  <li><strong>Contact Us</strong></li>
  <li><strong>Sitemap</strong></li>
  <li><strong>Static HTML URLs</strong></li>
  <li><strong>Live Chat</strong></li>
  <li><strong>Customer Feedback</strong></li>
  <li><strong>Different Price For Public And Retails</strong></li>
  <li><strong>Multiple Currency Support</strong></li>
  <li><strong>Multiple Language Support</strong></li>
  <li><strong>Blog</strong></li>
</ul>
<NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Core PHP <br /> E-Commerce  Package</h2>
                    <h1 className="d-flex justify-content-center">$2599/-</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
  <li><strong>Custom Layout Design</strong></li>
  <li><strong>Theme Integration</strong></li>
  <li><strong>JQUERY Slider Home Page</strong></li>
  <li><strong>Search</strong></li>
  <li><strong>Advance Search</strong></li>
  <li><strong>Sign Up, Sign In, Forget Password</strong></li>
  <li><strong>My Account</strong></li>
  <li><strong>Wishlist</strong></li>
  <li><strong>Shopping Cart</strong></li>
  <li><strong>Product Listing</strong></li>
  <li><strong>Product Detail Page</strong></li>
  <li><strong>Product Comparison</strong></li>
  <li><strong>Multiple Product Image</strong></li>
  <li><strong>Real Zoom On Product Image</strong></li>
  <li><strong>Write A Review</strong></li>
  <li><strong>Email To Friend</strong></li>
  <li><strong>Share On Social Sites (Pinit, Fb, Twitter, LinkedIn)</strong></li>
  <li><strong>Similar Products</strong></li>
  <li><strong>Latest Arrivals</strong></li>
  <li><strong>Best Sellers</strong></li>
  <li><strong>Guest Checkout</strong></li>
  <li><strong>Single Page Checkout</strong></li>
  <li><strong>Discount Coupon</strong></li>
  <li><strong>Abandon Cart</strong></li>
  <li><strong>Payment Gateway Integration (PayPal)</strong></li>
  <li><strong>Shipping Methods</strong></li>
  <li><strong>Testimonials</strong></li>
  <li><strong>Newsletter</strong></li>
  <li><strong>FAQs</strong></li>
  <li><strong>Contact Us</strong></li>
  <li><strong>Sitemap</strong></li>
  <li><strong>Static HTML URLs</strong></li>
  <li><strong>Live Chat</strong></li>
  <li><strong>Customer Feedback</strong></li>
  <li><strong>Different Price For Public And Retails</strong></li>
  <li><strong>Multiple Currency Support</strong></li>
  <li><strong>Multiple Language Support</strong></li>
  <li><strong>Blog</strong></li>
</ul>
<NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackagePage3;
