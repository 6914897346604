import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";
import SectionTestimonials2 from "./section-testimonials2";

export function SectionWhyChooseUsInner() {
    return (
        <>
            <div className="row" >
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-media">
                        <div className="sx-ab4-img-outer">
                            <div className="sx-ab4-media">
                                <ItodoImage src="images/services/images/choose6.jpg" alt="media" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-content">
                        <h2 className="sx-title" style={{color:"white"}}>Why Partner with First Page Rankerz?</h2>
                        <p style={{color:"#fdcb32"}}>First Page Rankerz is a leading digital marketing agency dedicated to helping businesses achieve their online goals. Discover the benefits of choosing us:</p>
                        <ul className="sx-arrow-circle-checked" >
                            <li style={{color:"white"}}>We offer a full suite of digital marketing services, including website development, SEO, Google Ads, and graphic design.</li>
                            <li style={{color:"white"}}>Our strategies are data-driven and focused on delivering measurable results for your business.</li>
                            <li style={{color:"white"}}>Our team of experienced professionals has a deep understanding of the latest digital marketing trends and best practices.</li>
                            <li style={{color:"white"}}>We tailor our services to meet your unique business needs and goals.</li>
                            <li style={{color:"white"}}> We prioritize customer satisfaction and provide exceptional support throughout the entire process.</li>
                        </ul>
                        {/* <NavLink to="/about-us" className="site-button sx-btn-primary icon sx-btn-lg">
                            <i className="fa fa-long-arrow-right" />
                            Learn More
                        </NavLink> */}
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionWhyChooseUs() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <SectionWhyChooseUsInner />
                        </div>
                        {/* Testimonial Section */}
                        <SectionTestimonials2 />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionWhyChooseUs;
