
function TermsAndConditions() {
    return (
        <section>
            <div className="container p-t80 p-b80">
                <div className="terms-content">
                    <h2>Terms and Conditions</h2>
                    <p>Welcome to World Wide Dispatch Management! These terms and conditions outline the rules and regulations for the use of our website, located at https://wwdispatchm.com/.</p>

                    <h3>1. Introduction</h3>
                    <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use World Wide Dispatch Management if you do not agree to all of the terms and conditions stated on this page.</p>

                    <h3>2. Intellectual Property Rights</h3>
                    <p>Unless otherwise stated, World Wide Dispatch Management and/or its licensors own the intellectual property rights for all material on this website. All intellectual property rights are reserved. You may access this from https://wwdispatchm.com/ for your own personal use subjected to restrictions set in these terms and conditions.</p>

                    <h3>3. User Content</h3>
                    <p>In these terms and conditions, “User Content” means any audio, video, text, images, or other material you choose to display on this website. By displaying your User Content, you grant World Wide Dispatch Management a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.</p>

                    <h3>4. No Warranties</h3>
                    <p>This website is provided "as is," with all faults, and World Wide Dispatch Management expresses no representations or warranties, of any kind related to this website or the materials contained on this website.</p>

                    <h3>5. Limitation of Liability</h3>
                    <p>In no event shall World Wide Dispatch Management, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website, whether such liability is under contract, tort, or otherwise.</p>

                    <h3>6. Indemnification</h3>
                    <p>You hereby indemnify to the fullest extent World Wide Dispatch Management from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these terms.</p>

                    <h3>7. Severability</h3>
                    <p>If any provision of these terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>

                    <h3>8. Variation of Terms</h3>
                    <p>World Wide Dispatch Management is permitted to revise these terms at any time as it sees fit, and by using this website you are expected to review these terms on a regular basis.</p>

                    <h3>9. Assignment</h3>
                    <p>World Wide Dispatch Management is allowed to assign, transfer, and subcontract its rights and/or obligations under these terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these terms.</p>

                    <h3>10. Entire Agreement</h3>
                    <p>These terms constitute the entire agreement between World Wide Dispatch Management and you in relation to your use of this website and supersede all prior agreements and understandings.</p>
                </div>
            </div>
        </section>
    )
}

export default TermsAndConditions;
