import SectionAboutCompany1 from "../../sections/home/index/section-about-company1";
import SectionWhyChooseUs1 from "../../sections/home/index/section-why-choose-us1";
import SectionWhyChooseUs3 from "../../sections/home/index3/section-why-choose-us3";
import SectionSlider3 from "../../sections/home/index3/section-slider3";
import SectionServices3 from "../../sections/home/index3/section-services3";
import SectionTestimonials3 from "../../sections/home/index3/section-testimonials3";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import SectionTeam2 from "../../sections/home/index3/section-team2";

function Home3Page() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    return (
        <>
            <SectionSlider3 />
            <SectionServices3 />
            {/* <SectionAboutCompany1 /> */}
            {/* <SectionTeam2 /> */}
            {/* <SectionWhyChooseUs1 /> */}
            <SectionWhyChooseUs3 />
            <SectionTestimonials3 />
        </>
    )
}

export default Home3Page;