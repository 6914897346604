import ItodoImage from "../../../elements/itodo-img";
import SectionCounter1 from "./section-counter1";

function SectionAboutCompany2() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-white sx-about-bx1-outer" style={{backgroundColor:"black"}}>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx1-content-wrap">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-about-bx1-media">
                                        {/* <div className="sx-dot-bx">
                                            <ItodoImage src="images/about/dotted-pic.png" alt="" />
                                        </div> */}
                                        <div className="sx-ab-img-outer">
                                            <div className="sx-ab-img-section-2">
                                                <ItodoImage src="images/services/images/choose2.jpg" alt="Company Image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-about-bx1-content">
                                        <h2 className="sx-title" style={{color:"white"}}>Expert Solutions in First Page Rankerz</h2>
                                        <span className="sx-title-2">Optimize. Excel. Outperform.</span>
                                        <p style={{color:"white"}}>At First Page Rankerz, we believe in building long-term partnerships with our clients. We take the time to understand their unique needs and develop tailored strategies that deliver tangible results. Our commitment to excellence, combined with our passion for digital marketing, sets us apart as a trusted partner for businesses seeking to succeed in the competitive online landscape.</p>
                                        <div className="progress">
                                            <div className="progress-bar sx-bg-primary" role="progressbar" style={{ width: '98%' }} aria-valuenow={98} aria-valuemin={0} aria-valuemax={100}>
                                                <span>98.00% Client Satisfaction</span>
                                            </div>
                                        </div>
                                        <div className="row sx-about-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <p style={{color:"white"}}>First Page Rankerz is your go-to agency for expert digital solutions. We specialize in SEO, Google Ads, Graphic Designing and website design to boost your online visibility.</p>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <p style={{color:"white"}}>Elevate your online presence with First Page Rankerz. We provide expert SEO and social media solutions to drive traffic and conversions.</p>
                                            </div>
                                        </div>
                                        <div className="row sx-about-icon-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    <div className="sx-icon-bx-info" >
                                                        <span className="sx-icon-bx-title" style={{color:"white"}}>Trusted by Clients</span>
                                                        <span className="sx-icon-bx-title-info" style={{color:"white"}}>15+ Years of Experience</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title" style={{color:"white"}}>Global Reach</span>
                                                        <span className="sx-icon-bx-title-info" style={{color:"white"}}>Serving 10+ Countries</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <SectionCounter1 /> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionAboutCompany2;
