import ItodoImage from "../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import { NavLink } from "react-router-dom";

function PackagePage4() {
  useEffect(() => {
    loadScript("js/custom.js");
  }, []);

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer " style={{backgroundColor:"black"}}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/content2.jpg"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Content Writing Packages
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <h1 className="sx-title d-flex justify-content-center" style={{ color: "#fdcb32" }}>Content Writing Packages</h1>
                <div className="sx-head-l-title">
                  <h3 style={{ color: "#fdcb32" }}>
                  Comprehensive content writing packages to meet your business needs and drive results.
                  </h3>
                </div>
              </div>
              {/* TITLE END */}

              {/* Packages Container */}
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Basic Content <br />Writing Package</h2>
                    <h1 className="d-flex justify-content-center">$279/Per Month</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
  <li><strong>Blog Setup</strong></li>
  <li><strong>Blogs Posts Per Month: 4</strong></li>
  <li><strong>No. of Words per Post: 400</strong></li>
  <li><strong>Article Post Per Month: 2</strong></li>
  <li><strong>No. of Words per Article: 400</strong></li>
  <li><strong>Guest Posts Per Month: 1</strong></li>
  <li><strong>No. of Words per Post: 400-500</strong></li>
  <li><strong>Search Engine Optimized</strong></li>
  <li><strong>Copyscape Passed Blogs</strong></li>
  <li><strong>Setup Facebook, Twitter, Google+</strong></li>
  <li><strong>Custom Social Media Channel Design (FB, G+, Twitter)</strong></li>
  <li><strong>Unique Social Posts Per Month: 12</strong></li>
  <li><strong>Premium Press Release/mo: 1</strong></li>
  <li><strong>Infographic/mo: 1</strong></li>
  <li><strong>Custom Video/mo: 1</strong></li>
  <li><strong>100% Originality</strong></li>
  <li><strong>100% Copyright Ownership</strong></li>
</ul>
<NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>

                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Standard Content <br /> Writing Package</h2>
                    <h1 className="d-flex justify-content-center">$499/One Time Set Up Fee</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
  <li><strong>Blog Setup</strong></li>
  <li><strong>Blogs Posts Per Month: 8</strong></li>
  <li><strong>No. of Words per Post: 400</strong></li>
  <li><strong>Article Post Per Month: 4</strong></li>
  <li><strong>No. of Words per Article: 400</strong></li>
  <li><strong>Guest Posts Per Month: 1</strong></li>
  <li><strong>No. of Words per Post: 400-500</strong></li>
  <li><strong>Search Engine Optimized</strong></li>
  <li><strong>Copyscape Passed Blogs</strong></li>
  <li><strong>Setup Facebook, Twitter, Google+</strong></li>
  <li><strong>Custom Social Media Channel Design (FB, G+, Twitter)</strong></li>
  <li><strong>Unique Social Posts Per Month: 12</strong></li>
  <li><strong>Premium Press Release/mo: 2</strong></li>
  <li><strong>Infographic/mo: 2</strong></li>
  <li><strong>Custom Video/mo: 2</strong></li>
  <li><strong>100% Originality</strong></li>
  <li><strong>100% Copyright Ownership</strong></li>
</ul>
<NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>

                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Professional Content <br /> Writing Package</h2>
                    <h1 className="d-flex justify-content-center">$899/One Time Set Up Fee</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
  <li><strong>Blog Setup</strong></li>
  <li><strong>Blogs Posts Per Month: 20</strong></li>
  <li><strong>No. of Words per Post: 400</strong></li>
  <li><strong>Article Post Per Month: 10</strong></li>
  <li><strong>No. of Words per Article: 400</strong></li>
  <li><strong>Guest Posts Per Month: 4</strong></li>
  <li><strong>No. of Words per Post: 400-500</strong></li>
  <li><strong>Search Engine Optimized</strong></li>
  <li><strong>Copyscape Passed Blogs</strong></li>
  <li><strong>Setup Facebook, Twitter, Google+</strong></li>
  <li><strong>Custom Social Media Channel Design (FB, G+, Twitter)</strong></li>
  <li><strong>Unique Social Posts Per Month: 36</strong></li>
  <li><strong>Premium Press Release/mo: 4</strong></li>
  <li><strong>Infographic/mo: 4</strong></li>
  <li><strong>Custom Video/mo: 4</strong></li>
  <li><strong>100% Originality</strong></li>
  <li><strong>100% Copyright Ownership</strong></li>
</ul>
<NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackagePage4;
