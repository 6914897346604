import { Route, Routes } from "react-router-dom";
import AboutUsPage from "../app/components/company/about-us";
import FAQPage from "../app/components/company/faq";
import IconsPage from "../app/components/company/icons";
import TeamPage from "../app/components/company/pricing";
import TestimonialsPage from "../app/components/company/testimonials";
import ServicesPage from "../app/components/IT solutions/services/services";
import ContactUsPage from "../app/components/contactus/contact-us";
import Home3Page from "../app/components/home/index3";
import ServicesDetailPage2 from "../app/components/IT solutions/services/detail2";
import ServicesDetailPage1 from "../app/components/IT solutions/services/detail1";
import ServicesDetailPage3 from "../app/components/IT solutions/services/detail3";
import ServicesDetailPage4 from "../app/components/IT solutions/services/detail4";
import ServicesDetailPage5 from "../app/components/IT solutions/services/detail5";
import ServicesDetailPage6 from "../app/components/IT solutions/services/detail6";
import TermsAndConditions from "../app/sections/company/term-and-condition";
import ServicesDetailPage from "../app/components/IT solutions/services/detail";
import PackagePage1 from "../app/components/company/package1";
import PackagePage2 from "../app/components/company/package2";
import PackagePage3 from "../app/components/company/package3";
import PackagePage4 from "../app/components/company/package4";
import PackagePage5 from "../app/components/company/package5";

function AppRoutes() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home3Page />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/term-and-condition" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<TermsAndConditions />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/icons" element={<IconsPage />} />
                <Route path="/testimonials" element={<TestimonialsPage />} />
                <Route path="/pricing" element={<TeamPage />} />
                {/* <Route path="/mission" element={<MissionVisionPage />} /> */}
                <Route path="/services" element={<ServicesPage />} />
                {/* <Route path="/services/Taxi-Dispatch-Outsourcing-Solutions" element={<ServicesDetailPage />} /> */}
                <Route path="/services/Content-Writing-Services" element={<ServicesDetailPage1 />} />
                <Route path="/services/Social-Media-Optimization-Services" element={<ServicesDetailPage2 />} />
                <Route path="/services/Search-Engine-Optimization-Services" element={<ServicesDetailPage3 />} />
                <Route path="/services/Website-Development-Services" element={<ServicesDetailPage4 />} />
                <Route path="/services/Graphic-Designing-Services" element={<ServicesDetailPage5 />} />
                <Route path="/services/Google-Ads-Services" element={<ServicesDetailPage6 />} />
                <Route path="/pricing/SEO-Packages" element={<PackagePage1 />} />
                <Route path="/pricing/Website-Development-CMS-Packages" element={<PackagePage2 />} />
                <Route path="/pricing/E-Commerce-Websites-Development-Pacakges" element={<PackagePage3 />} />
                <Route path="/pricing/Content-Writing-Packages" element={<PackagePage4 />} />
                <Route path="/pricing/SMO-Packages" element={<PackagePage5 />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
            </Routes>
        </>
    )
}

export default AppRoutes;