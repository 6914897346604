import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage3() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer" style={{ backgroundColor: "black" }}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/seo-head.jpg"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Search Engine Optimization Services
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title">Why Choose Us</div>
                <h2 className="sx-title" style={{ color: "#fff" }}>Search Engine Optimization Services</h2>
                <div className="sx-head-l-title">
                  <p style={{ color: "#fff" }}>
                    First Page Rankerz: Your trusted partner for comprehensive SEO solutions. With our data-driven approach and experienced team, we deliver measurable results that help you achieve higher rankings and drive organic traffic to your website.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3 style={{ color: "#fdcb32" }}>Our Expertise:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li style={{ color: "white" }}>
                          <strong>Data-Driven Approach:</strong> We utilize advanced analytics tools and techniques to gain valuable insights into your target audience and industry trends. This data-driven approach ensures that our SEO strategies are tailored to deliver maximum results.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Keyword Research Expertise:</strong> Our team conducts in-depth keyword research to identify the most relevant terms and phrases that will attract your ideal customers. We optimize your website content and meta tags to improve your search engine rankings.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Technical SEO Optimization:</strong> We meticulously analyze your website's technical aspects, including site speed, mobile-friendliness, and indexing issues. By addressing these factors, we can enhance your website's performance and search engine visibility.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Content Marketing Mastery:</strong> Our content writers create high-quality, engaging content that resonates with your target audience. We optimize this content for search engines while ensuring it aligns with your brand message and marketing goals.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Comprehensive Link Building Strategies:</strong> We employ effective link building techniques to improve your website's authority and credibility. This includes building natural backlinks from high-quality websites and relevant online resources.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/seo4.avif"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage src="images/services/images/seo3.jpg" alt="image" />
                    </div>
                    <div className="col-lg-6 col-md-6 pt-4">
                      <h3 style={{ color: "#fdcb32" }}>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li style={{ color: "white" }}>
                          <strong>Reliability</strong>
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Transparency and Open Communication</strong>
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Customized Solutions</strong>
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Continuous Improvement</strong>
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Results-Oriented Focus</strong>
                        </li>
                      </ul>
                      <p style={{ color: "#fff" }}>
                        We'll work closely with you to understand your unique business goals and develop a customized digital marketing strategy that drives results. Let's partner together to elevate your online presence and achieve your business objectives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage3;
