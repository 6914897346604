export const bannerData = {
  about: {
    background: "images/banner/about-us-header.webp",
    title: "About Us",
    crumb: "About Us",
    watermark: "It Solution",
  },
  faq: {
    background: "images/banner/bnr-4.jpg",
    title: "Faq Page",
    crumb: "Faq Page",
    watermark: "It Solution",
  },
  gallery: {
    background: "images/banner/bnr-2.jpg",
    title: "Gallery Page",
    crumb: "Gallery Page",
    watermark: "It Solution",
  },
  icons: {
    background: "images/banner/bnr-4.jpg",
    title: "Icons",
    crumb: "icon-font",
    watermark: "It Solution",
  },
  testimonials: {
    background: "images/banner/bnr-3.jpg",
    title: "Testimonials",
    crumb: "Testimonials",
    watermark: "It Solution",
  },
  whychooseus: {
    background: "images/banner/bnr-8.jpg",
    title: "Why Choose us",
    crumb: "Why Choose us",
    watermark: "It Solution",
  },
  pricing: {
    background: "images/banner/bnr-7.jpg",
    title: "Price Table",
    crumb: "Price Table",
    watermark: "It Solution",
  },
  team: {
    background: "images/banner/price1.jpg",
    title: "Pricing",
    crumb: "Pricing",
    watermark: "It Solution",
  },
  mission: {
    background: "images/banner/bnr-7.jpg",
    title: "Mission & Vision",
    crumb: "Mission & Vision",
    watermark: "It Solution",
  },
  services: {
    background: "images/banner/services.jpeg",
    title: "Our Services",
    crumb: "Our Services",
    // watermark: "It Solution",
  },
  // portfolio1: {
  //   background: "images/banner/bnr-4.jpg",
  //   title: "Portfolio Page",
  //   crumb: "Portfolio",
  //   watermark: "It Solution",
  // },
  // portfolio2: {
  //   background: "images/banner/bnr-5.jpg",
  //   title: "Portfolio-2 Page",
  //   crumb: "Portfolio-2 Page",
  //   watermark: "It Solution",
  // },
  // portfoliodetail: {
  //   background: "images/banner/bnr-7.jpg",
  //   title: "Portfolio Detail",
  //   crumb: "Portfolio Detail",
  //   watermark: "It Solution",
  // },
  // bloggrid: {
  //   background: "images/banner/bnr-1.jpg",
  //   title: "Blog Page",
  //   crumb: "Blog Page",
  //   watermark: "It Solution",
  // },
  // bloglistsidebar: {
  //   background: "images/banner/bnr-8.jpg",
  //   title: "Blog Archive",
  //   crumb: "Blog Single Grid",
  //   watermark: "It Solution",
  // },
  // blogdetail: {
  //   background: "images/banner/bnr-1.jpg",
  //   title: "Blog detail",
  //   crumb: "Blog detail",
  //   watermark: "It Solution",
  // },
  // blogdetailsidebar: {
  //   background: "images/banner/bnr-10.jpg",
  //   title: "Blog detail",
  //   crumb: "Blog detail",
  //   watermark: "It Solution",
  // },
  contact: {
    background: "images/banner/contact2.jpg",
    title: "Contact Us",
    crumb: "Contact Us",
    // watermark: "It Solution",
  },
};
