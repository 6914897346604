import ItodoImage from "../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import { NavLink } from "react-router-dom";

function PackagePage2() {
  useEffect(() => {
    loadScript("js/custom.js");
  }, []);

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer " style={{backgroundColor:"black"}}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/webp.jpg"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Website Development CMS Packages
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <h1 className="sx-title d-flex justify-content-center" style={{ color: "#fdcb32" }}>CMS Packages</h1>
                <div className="sx-head-l-title">
                  <h3 style={{ color: "#fdcb32" }}>
                    Choose the perfect plan for your website development needs.
                  </h3>
                </div>
              </div>
              {/* TITLE END */}

              {/* Packages Container */}
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Basic CMS <br /> Package</h2>
                    <h1 className="d-flex justify-content-center">$600/-</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
                      <li><strong>No of Pages – Upto 5</strong></li>
                      <li><strong>Additional Pages $100</strong></li>
                      <li><strong>Responsive Design</strong></li>
                      <li><strong>Custom Layout Design</strong></li>
                      <li><strong>SEO Semantic HTML Code</strong></li>
                      <li><strong>CMS</strong></li>
                      <li><strong>Integrated Contact Forms w/Captcha</strong></li>
                      <li><strong>Main Banner Management</strong></li>
                      <li><strong>News Management (if applicable)</strong></li>
                      <li><strong>Blog</strong></li>
                      <li><strong>Event Calendar (if applicable)</strong></li>
                      <li><strong>Event Booking (if applicable)</strong></li>
                      <li><strong>Testimonials Management</strong></li>
                      <li><strong>Newsletter</strong></li>
                      <li><strong>Social Media Buttons</strong></li>
                      <li><strong>Brochure Management</strong></li>
                      <li><strong>Jobs Management</strong></li>
                      <li><strong>SEO Plugin</strong></li>
                      <li><strong>Google Location Map</strong></li>
                      <li><strong>XML Sitemap</strong></li>
                      <li><strong>URL Submission in SEs'</strong></li>
                      <li><strong>Google Analytics</strong></li>
                      <li><strong>Website Training Up to 2Hrs</strong></li>
                    </ul>
                    <NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Standard CMS <br /> Package</h2>
                    <h1 className="d-flex justify-content-center">$800/-</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
                      <li><strong>No of Pages – Upto 10</strong></li>
                      <li><strong>Additional Pages $100</strong></li>
                      <li><strong>Responsive Design</strong></li>
                      <li><strong>Custom Layout Design</strong></li>
                      <li><strong>SEO Semantic HTML Code</strong></li>
                      <li><strong>CMS</strong></li>
                      <li><strong>Integrated Contact Forms w/Captcha</strong></li>
                      <li><strong>Main Banner Management</strong></li>
                      <li><strong>News Management (if applicable)</strong></li>
                      <li><strong>Blog</strong></li>
                      <li><strong>Event Calendar (if applicable)</strong></li>
                      <li><strong>Event Booking (if applicable)</strong></li>
                      <li><strong>Testimonials Management</strong></li>
                      <li><strong>Newsletter</strong></li>
                      <li><strong>Social Media Buttons</strong></li>
                      <li><strong>Brochure Management</strong></li>
                      <li><strong>Jobs Management</strong></li>
                      <li><strong>SEO Plugin</strong></li>
                      <li><strong>Google Location Map</strong></li>
                      <li><strong>XML Sitemap</strong></li>
                      <li><strong>URL Submission in SEs'</strong></li>
                      <li><strong>Google Analytics</strong></li>
                      <li><strong>Website Training Up to 3Hrs</strong></li>
                    </ul>
                    <NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Professional CMS <br /> Package</h2>
                    <h1 className="d-flex justify-content-center">$1000/-</h1>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
                      <li><strong>No of Pages – Upto 15</strong></li>
                      <li><strong>Additional Pages $100</strong></li>
                      <li><strong>Responsive Design</strong></li>
                      <li><strong>Custom Layout Design</strong></li>
                      <li><strong>SEO Semantic HTML Code</strong></li>
                      <li><strong>CMS</strong></li>
                      <li><strong>Integrated Contact Forms w/Captcha</strong></li>
                      <li><strong>Main Banner Management</strong></li>
                      <li><strong>News Management (if applicable)</strong></li>
                      <li><strong>Blog</strong></li>
                      <li><strong>Event Calendar (if applicable)</strong></li>
                      <li><strong>Event Booking (if applicable)</strong></li>
                      <li><strong>Testimonials Management</strong></li>
                      <li><strong>Newsletter</strong></li>
                      <li><strong>Social Media Buttons</strong></li>
                      <li><strong>Brochure Management</strong></li>
                      <li><strong>Jobs Management</strong></li>
                      <li><strong>SEO Plugin</strong></li>
                      <li><strong>Google Location Map</strong></li>
                      <li><strong>XML Sitemap</strong></li>
                      <li><strong>URL Submission in SEs'</strong></li>
                      <li><strong>Google Analytics</strong></li>
                      <li><strong>Website Training Up to 5Hrs</strong></li>
                    </ul>
                    <NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackagePage2;
