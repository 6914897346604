import { publicUrlFor } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";
import "./section-why-choose-us1.css";
function SectionWhyChooseUs1() {
  return (
    <>
      <div
        className="section-full p-t110 p-b80 sx-why-chooseus-outer sx-bg-light bg-cover"
        style={{ backgroundColor: "#fdcb32"}}
      >
        <div className="container" style={{ backgroundColor: "black"}}>
          {/* TITLE START */}
          <div className="section-head center">
            <div className="sx-head-s-title" style={{color:"#fdcb32"}}>
              Why Choose Us
            </div>
            <div className="sx-head-l-title">
              <h2 className="sx-title" style={{ color: "#fff" }}>
                Our Work Services
              </h2>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="sx-icon-bx-wrap">
              <div
                className="row"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {/* Block one */}
                {/* <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "/images/services/images/texi1.webp"
                          )}
                          alt="NEMT Dispatch Outsourcing Solutions"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Taxi-Dispatch-Outsourcing-Solutions">
                          <h4 className="sx-tilte">
                            Taxi Dispatch Outsourcing Solutions
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Optimizing taxi dispatch operations to ensure
                          efficient and reliable service.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* Repeat the same structure for each block */}
                {/* <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor("images/services/images/limo1.webp")}
                          alt="NEMT Dispatch Outsourcing Solutions"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Limo-Dispatch-Outsourcing-Solutions">
                          <h4 className="sx-tilte">
                            Limo Dispatch Outsourcing Solutions
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Providing premium dispatch solutions for limousine
                          services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "/images/services/images/neom2.webp"
                          )}
                          alt="NEMT Dispatch Outsourcing Solutions"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Non-Emergency-Medical-Transportation-(NEMT)-Dispatch-Outsourcing">
                          <h4 className="sx-tilte">
                            NEMT Dispatch Outsourcing Solutions
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Specialized dispatch services for non-emergency
                          medical transportation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor("images/services/images/seo4.avif")}
                          alt="Search Engine Optimization"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Search-Engine-Optimization-Services">
                          <h4 className="sx-tilte">Search Engine Optimization</h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                        Providing expert SEO services to help businesses improve their online visibility and reach their target audience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "images/services/images/website2.webp"
                          )}
                          alt="Website Development Services"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Website-Development-Services">
                          <h4 className="sx-tilte">
                            Website Development Services
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                          Professional website development tailored to your
                          business needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "images/services/images/content1.jpg"
                          )}
                          alt="Graphic Desiging"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Graphic-Designing-Services">
                          <h4 className="sx-tilte">
                            Graphic Desiging
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                        Delivering top-notch graphic design solutions to enhance your brand's visual identity.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "images/services/images/ads3.jpg"
                          )}
                          alt=" Google Ads"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Google-Ads-Services">
                          <h4 className="sx-tilte">
                            Google Ads
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                        Crafting effective Google Ads campaigns to drive targeted traffic and increase conversions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor(
                            "/images/services/images/content1.jpg"
                          )}
                          alt="NEMT Dispatch Outsourcing Solutions"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Content-Writing-Services">
                          <h4 className="sx-tilte">
                           Content Writing
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                        Crafting compelling content that engages your audience and drives results.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-6 wow fadeInDown"
                  data-wow-duration="1000ms"
                  style={{ display: "flex" }}
                >
                  <div
                    className="sx-icon-bx-1"
                    style={{
                      flex: "1 1 auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="sx-icon-box-wraper" style={{ flex: "1" }}>
                      <div className="sx-icon-bx-img one">
                        <img
                          src={publicUrlFor("images/services/images/social2.webp")}
                          alt="NEMT Dispatch Outsourcing Solutions"
                        />
                      </div>
                      <div
                        className="icon-content"
                        style={{
                          flex: "1",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <NavLink to="/services/Social-Media-Optimization-Services">
                          <h4 className="sx-tilte">
                            Social Media Optimization
                          </h4>
                        </NavLink>
                        <p style={{ flex: "1" }}>
                        Optimizing your social media presence to reach a wider audience and engage with your followers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionWhyChooseUs1;
