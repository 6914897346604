import { NavLink } from "react-router-dom";
import ItodoImage from "../../../elements/itodo-img";

function SectionServices1() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-our-services-outer sx-bg-light" style={{  backgroundColor: "#fdcb32"}}>
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head center" >
                     <div className="sx-head-s-title" ><h4 style={{  color: "black"}}>Our Services</h4> </div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title" style={{  color: "black"}}>Elevate Your Online Presence with Our Expert Services</h2>
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="sx-our-services-bx">
                            <div className="row">
                               
                                <div className="col-lg-4 col-md-6 d-flex" >
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/seo5.png" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column"  style={{  color: "black"}}>
                                                <h4 className="sx-tilte" style={{  color: "black"}}>Search Engine Optimization Services</h4>
                                                <p>Providing expert SEO services to help businesses improve their online visibility and reach their target audience.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Search-Engine-Optimization-Services" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/coding.jpg" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte" style={{  color: "black"}}>Website Development Services</h4>
                                                <p  style={{  color: "black"}}>Creating innovative and user-centric websites to enhance your online presence and drive business growth.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Website-Development-Services" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/graphic.jpg" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte" style={{  color: "black"}}>Graphic Designing Services</h4>
                                                <p  style={{  color: "black"}}>Delivering top-notch graphic design solutions to enhance your brand's visual identity.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Graphic-Designing-Services" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/google.png" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte" style={{  color: "black"}}>Google Ads Services</h4>
                                                <p  style={{  color: "black"}}>Crafting effective Google Ads campaigns to drive targeted traffic and increase conversions.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Google-Ads-Services" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary" ><ItodoImage src="images/services/images/content3.webp" alt="image" style={{borderRadius:'50%'}} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte" style={{  color: "black"}}>Content Writing</h4>
                                                <p  style={{  color: "black"}}>Crafting compelling content that engages your audience and drives results.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Content-Writing-Services" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/social1.jpg" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte" style={{  color: "black"}}>Social Media Optimization</h4>
                                                <p  style={{  color: "black"}}>Optimizing your social media presence to reach a wider audience and engage with your followers.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/services/Social-Media-Optimization-Services" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionServices1;
