import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage1() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer " style={{backgroundColor:"black"}}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/content2.jpg"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Content Writing Services
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title">Why Choose Us</div>
                <h2 className="sx-title" style={{color:"white"}}>
                  Content Writing Services
                </h2>
                <div className="sx-head-l-title">
                  <p style={{color:"white"}}>
                  First Page Rankerz offers comprehensive content writing services tailored to meet your unique business needs. Our team of skilled writers creates engaging and informative content that resonates with your target audience. From blog posts and articles to website copy and social media content, we provide high-quality writing that effectively communicates your brand message and drives results.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12" >
                <div className="sx-about-bx4-content">
                  <div className="row" >
                    <div className="col-lg-6 col-md-6" >
                      <h3 style={{color:"#fdcb32"}}>Our Offerings:</h3>
                      <ul className="sx-arrow-circle-checked">
  <li style={{color: "white"}}>
    <strong>Expert Writers:</strong> Our team of skilled writers possesses a deep understanding of various industries and can create content that resonates with your target audience.
  </li>
  <li style={{color: "white"}}>
    <strong>Tailored Content:</strong>{" "}
    We work closely with you to understand your unique needs and goals, ensuring that our content is aligned with your brand message and marketing objectives.
  </li>
  <li style={{color: "white"}}>
    <strong>SEO Optimization:</strong> Our writers are well-versed in SEO best practices, incorporating relevant keywords and optimizing content for search engines to improve your online visibility.
  </li>
  <li style={{color: "white"}}>
    <strong>Engaging Content:</strong> We create compelling and informative content that captures the attention of your readers and encourages them to take action.
  </li>
  <li style={{color: "white"}}>
    <strong>Timely Delivery:</strong> We adhere to strict deadlines and deliver high-quality content on time, ensuring that your marketing campaigns run smoothly.
  </li>
</ul>

                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage src="images/services/images/content4.avif" alt="image" />
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage src="images/services/images/content3.webp" alt="image" />
                    </div>
                    <div className="col-lg-6 col-md-6">
                    <h3 style={{color: "#fdcb32"}}>Our Commitments:</h3>
<ul className="sx-arrow-circle-checked">
  <li style={{ color: 'white' }}>
    <strong>Quality Content:</strong> We are committed to delivering high-quality, engaging content that meets your specific requirements.
  </li>
  <li style={{ color: 'white' }}>
    <strong>Client Satisfaction:</strong> Your satisfaction is our top priority. We will work closely with you to ensure that you are happy with the final product.
  </li>
  <li style={{ color: 'white' }}>
    <strong>SEO Optimization:</strong> Our content will be optimized for search engines to improve your online visibility.
  </li>
  <li style={{ color: 'white' }}>
    <strong>Continuous Improvement:</strong> We are always striving to improve our skills and stay up-to-date with the latest trends in content writing.
  </li>
</ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage1;
