import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage5() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer" style={{ backgroundColor: "black" }}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/designing.jpg"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Graphic Designing Services
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title" >Why Choose Us</div>
                <h2 className="sx-title" style={{ color: "#fff" }}>
                  Graphic Designing Services
                </h2>
                <div className="sx-head-l-title">
                  <p style={{ color: "#fff" }}>
                    First Page Rankerz: Elevate your brand's visual identity with our expert graphic design services. Our talented team creates stunning designs that capture your brand's essence and resonate with your target audience. From logo design and branding to marketing materials and website graphics, we deliver high-quality visuals that leave a lasting impression.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3 style={{ color: "#fdcb32" }}>Our Expertise:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li style={{ color: "white" }}>
                          <strong> Creative Excellence:</strong> Our team of talented designers brings a wealth of creativity and innovation to every project. We specialize in crafting visually stunning designs that capture your brand's unique identity.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Brand Consistency:</strong> We ensure that all your visual assets align with your brand guidelines, creating a cohesive and recognizable brand image.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Versatility:</strong> Our designers are skilled in a wide range of design styles and techniques, allowing us to adapt to your specific needs and preferences.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Attention to Detail:</strong> We pay meticulous attention to detail, ensuring that every element of your design is polished and professional.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Client-Centric Approach:</strong> We work closely with our clients to understand their vision and goals, tailoring our designs to meet their specific requirements.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/design1.avif"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="row pt-4 mt-4">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/graphic3.avif"
                        alt="image"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h3 style={{ color: "#fdcb32" }}>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li style={{ color: "white" }}>
                          <strong>Results-Driven Approach:</strong> We deliver measurable results aligned with your business objectives.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Brand Consistency:</strong> We are committed to maintaining a consistent brand identity across all your marketing materials.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Transparency and Communication:</strong> We keep you informed every step of the way, providing regular updates and recommendations.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Client Satisfaction:</strong> Your satisfaction is our top priority. We will work closely with you to ensure that your design meets your expectations.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Timely Delivery:</strong> We understand the importance of deadlines. We will deliver your designs on time, without compromising on quality.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage5;
