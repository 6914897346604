import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage2() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer " style={{ backgroundColor: "black" }}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="/images/services/images/social3.webp"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Social Media Optimization Services
              </h1>
            </div>
          </div>
        </div>
        <div className="container" >
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title">Why Choose Us</div>
                <h2 className="sx-title" style={{ color: "white" }}>
                  Social Media Optimization Services
                </h2>
                <div className="sx-head-l-title">
                  <p style={{ color: "white" }}>
                    At World Wide Dispatch Management we recognize the vital
                    importance of non-emergency medical transportation in
                    ensuring access to healthcare for individuals with mobility
                    challenges. Our NEMT Dispatch Outsourcing solutions are
                    designed to support healthcare providers, medical
                    facilities, and transportation companies in efficiently
                    coordinating non-emergency medical trips for patients
                    worldwide.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3 style={{ color: "#fdcb32" }}>Our Expertise:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li style={{ color: "white" }}>
                          <strong>Comprehensive Strategy Development:</strong> We create tailored social media strategies that align with your business goals and target audience. Our experts analyze your existing social media presence and identify areas for improvement.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Engaging Content Creation:</strong> Our skilled content writers produce compelling and shareable content that resonates with your audience. We understand the nuances of different social media platforms and optimize our content accordingly.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Community Management:</strong> We actively engage with your audience, responding to comments, messages, and reviews in a timely and professional manner. This helps to build strong relationships and foster loyalty.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Analytics-Driven Optimization:</strong> We use advanced analytics tools to track the performance of your social media campaigns and make data-driven adjustments to improve results.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/social2.webp"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/social1.jpg"
                        alt="image"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h3 style={{ color: "#fdcb32" }}>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li style={{ color: "white" }}>
                          <strong>Results-Oriented Approach</strong>
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Engaging Content</strong>
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Active Community Management</strong>
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Data-Driven Optimization</strong>
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Platform Expertise</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage2;
