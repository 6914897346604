import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage4() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer" style={{ backgroundColor: "black" }}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/web development.webp"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Website Development Services
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title" style={{ color: "#fdcb32" }}>Why Choose Us</div>
                <h2 className="sx-title" style={{ color: "#fff" }}>Website Development Services</h2>
                <div className="sx-head-l-title">
                  <p style={{ color: "#fff" }}>
                  
First Page Rankerz offers comprehensive web development services to help businesses establish a strong online presence. Our team of skilled developers specializes in creating custom websites that are not only visually appealing but also user-friendly and optimized for search engines. We utilize the latest technologies and industry best practices to deliver high-quality web solutions that meet your unique requirements and drive business growth.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3 style={{ color: "#fdcb32" }}>Our Expertise:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li style={{ color: "white" }}>
                          <strong>Custom Website Design:</strong> Our talented
                          designers create unique, brand-reflecting designs that
                          bring your vision to life.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Responsive Development:</strong> We develop
                          fully responsive websites optimized for all devices,
                          ensuring a seamless user experience.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Content Management Systems (CMS):</strong> We
                          specialize in building user-friendly CMS-powered
                          websites for easy content management.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>E-commerce Solutions:</strong> Our secure,
                          scalable, and user-friendly e-commerce solutions
                          provide a seamless shopping experience.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Search Engine Optimization (SEO):</strong> We
                          incorporate SEO best practices for maximum visibility
                          and search engine rankings.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Integration Services:</strong> We integrate
                          third-party tools and services to streamline business
                          processes and enhance user experience.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Performance Optimization:</strong> We optimize
                          website performance for fast loading times, smooth
                          navigation, and optimal user experience.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Security Measures:</strong> We implement
                          robust security measures to protect your website from
                          cyber threats and unauthorized access.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/website1.webp"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/website2.webp"
                        alt="image"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h3 style={{ color: "#fdcb32" }}>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li style={{ color: "white" }}>
                          <strong>Quality Assurance:</strong> We deliver
                          high-quality websites meeting the highest standards of
                          design, functionality, and performance.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Customer Satisfaction:</strong> We prioritize
                          customer satisfaction, working closely with clients to
                          understand their needs and exceed expectations.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Transparency and Communication:</strong> We
                          believe in transparency and open communication,
                          keeping clients informed throughout the development
                          process.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Timely Delivery:</strong> We meet deadlines
                          and deliver projects on time without compromising
                          quality or functionality.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Ongoing Support and Maintenance:</strong> We
                          offer ongoing support and maintenance services to keep
                          your website running smoothly and securely.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage4;
