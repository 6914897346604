import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";
import SectionTestimonials2 from "./section-testimonials2";

export function SectionWhyChooseUs2Inner() {
    return (
        <>
            <div className="row" style={{backgroundColor:"black"}}>
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-media">
                        <div className="sx-ab4-img-outer">
                            <div className="sx-ab4-media">
                                <ItodoImage src="images/services/images/choose5.jpg" alt="media" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-content">
                        <h2 className="sx-title" style={{color:"white"}}>Why Choose First Page Rankerz?</h2>
                        <p style={{color:"#fdcb32"}}> Our goal is to help our clients increase their online visibility, attract more traffic, and generate leads. We believe in building long-term partnerships with our clients and delivering results that exceed their expectations.</p>
                        <ul className="sx-arrow-circle-checked">
  <li style={{ color: 'white' }}>We have a proven track record of delivering results for businesses of all sizes.</li>
  <li style={{ color: 'white' }}>Our strategies are tailored to meet your specific goals and budget.</li>
  <li style={{ color: 'white' }}>Experienced and professional team</li>
  <li style={{ color: 'white' }}>We maintain open and transparent communication with our clients.</li>
  <li style={{ color: 'white' }}>Competitive pricing and transparent fees</li>
</ul>
                        <NavLink to="/about-us" className="site-button sx-btn-primary icon sx-btn-lg" >
                            <i className="fa fa-long-arrow-right" />
                            Learn More
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionWhyChooseUs2() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <SectionWhyChooseUs2Inner />
                        </div>
                        {/* Testimonial Section */}
                        <SectionTestimonials2 />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionWhyChooseUs2;
