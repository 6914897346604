import { NavLink, useLocation } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";
import { useState, useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import "./Header.css";

function Header1() {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  function toggleNavClass() {
    setIsActive(!isActive);
  }

  useEffect(() => {
    loadScript("js/mobilenav.js");
  }, []); // Added dependency array to prevent unnecessary re-renders

  return (
    <>

<div className="solar-topbar-section" style={{ backgroundColor: 'black', padding: '10px 0' }}>
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-md-6">
        <div className="solar-top-menu" style={{ display: 'flex', alignItems: 'center' }}>
          <ul style={{ display: 'flex', listStyleType: 'none', margin: 0, padding: 0 }}>
            <li style={{ marginRight: '20px' }} className="line">
              <a href="tel:+1 (307) 218-3277" style={{ color: '#fdcb32', textDecoration: 'none' }}>
                <i className="bi bi-telephone"></i> +1 (307) 218-3277
              </a>
             
            </li>
            
            <li>
              <a href="mailto:info@firstpagerankerz.com" style={{ color: '#fdcb32', textDecoration: 'none' }}>
                <i className="bi bi-envelope"></i> info@firstpagerankerz.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


      <header
        className={
          "header-style1 site-header mobile-sider-drawer-menu " +
          (isActive ? "active" : "")
        }
      >
        {/* #291f3b dark purple color */}
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar">
            <div className="container clearfix">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one" >
                <NavLink to="/" style={{ background: "transparent" }}>
                    <ItodoImage 
                      src="images/fpr.png" 
                      alt="" 
                      style={{
                        width: '230px', 
                        // height: '130px', 
                        borderRadius: '60%', 
                        objectFit: 'cover' 
                      }} />
                  </NavLink>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                className="navbar-toggler collapsed"
                onClick={toggleNavClass}
                style={{backgroundColor:"#fdcb32"}}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              {/* MAIN NAVIGATION */}
              <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse">
                <ul className="nav navbar-nav">
                  <li className={location.pathname === "/" ? "active" : ""}>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li
                    className={
                      location.pathname.startsWith("/services")
                        ? "active"
                        : ""
                    }
                  >
                    <NavLink to="/services">Services</NavLink>
                    <ul className="sub-menu">  
                      <li>
                        <NavLink to="/services/Search-Engine-Optimization-Services">Search Engine Optimization</NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Website-Development-Services">
                          Web Designing and Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Graphic-Designing-Services">
                          Graphic Designing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Google-Ads-Services">Google Ads</NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Content-Writing-Services">
                         Content Writing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services/Social-Media-Optimization-Services">
                         Social Media Optimization
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      location.pathname === "/pricing" ? "active" : ""
                    }
                  >
                    <NavLink to="/pricing">Pricing</NavLink>
                    <ul className="sub-menu">
                      <li><NavLink to="/pricing/SEO-Packages">SEO Packages</NavLink></li>
                      <li><NavLink to="/pricing/Website-Development-CMS-Packages">Website Development CMS Packages</NavLink></li>
                      <li><NavLink to="/pricing/E-Commerce-Websites-Development-Pacakges">E-Commerce Websites Development Pacakges</NavLink></li>
                      <li><NavLink to="/pricing/Content-Writing-Packages">Content Writing Packages</NavLink></li>
                      <li><NavLink to="/pricing/SMO-Packages">SMO Packages</NavLink></li>
                    </ul>
                  </li>
                  <li
                    className={
                      location.pathname === "/about-us" ? "active" : ""
                    }
                  >
                    <NavLink to="/about-us">About Us</NavLink>
                  </li>
                  <li
                    className={
                      location.pathname === "/contact-us" ? "active" : ""
                    }
                  >
                    <NavLink to="/contact-us">Contact Us</NavLink>
                  </li>

                  {/* <div className="widget_about-call-section">
                                        <div className="sx-f-call-icon">
                                            <i className="flaticon-telephone" />
                                        </div>
                                        <div className="sx-f-call-section">
                                            <span >Contact us 9 to 5</span>
                                            <a href="tel:+1 (307) 218-32776">+1 (307) 218-3277</a>
                                        </div>
                                    </div> */}


                  <div className="main-menu__btn-box ">
                    <a href="tel:+1 (307) 218-3277"  className="main-menu__btn thm-btn">
                    <span className="call bi bi-telephone"></span> 
                      +1 (307) 218-3277</a>
                                        </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header1;
