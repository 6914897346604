import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage6() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer" style={{ backgroundColor: "black" }}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/ads2.jpg"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Google Ads Services
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <div className="sx-head-s-title" >Why Choose Us</div>
                <h2 className="sx-title" style={{ color: "#fff" }}>
                  Google Ads Services
                </h2>
                <div className="sx-head-l-title">
                  <p style={{ color: "#fff" }}>
                    First Page Rankerz: Elevate your online advertising with our expert Google Ads management. Our team of skilled professionals will craft targeted campaigns that drive qualified traffic to your website and increase conversions. With a data-driven approach and a focus on ROI, we'll help you maximize your advertising budget and achieve your business goals.
                  </p>
                </div>
              </div>
              {/* TITLE END */}
              <div className="col-lg-12 col-md-12">
                <div className="sx-about-bx4-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h3 style={{ color: "#fdcb32" }}>Our Expertise:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li style={{ color: "white" }}>
                          <strong>Data-Driven Strategies:</strong> We leverage advanced analytics to optimize your Google Ads campaigns for maximum ROI.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Keyword Expertise:</strong> Our team conducts in-depth keyword research to identify the most effective terms for your target audience.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Campaign Optimization:</strong> We continuously monitor and refine your campaigns to improve performance and reduce costs.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Conversion Tracking:</strong> We implement robust conversion tracking to measure the success of your campaigns and make data-driven adjustments.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Customized Approach:</strong> Our strategies are tailored to your specific business goals and budget, ensuring optimal results.
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/ads.jpg"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="row pt-4 mb-4">
                    <div className="col-lg-6 col-md-6">
                      <ItodoImage
                        src="images/services/images/ads3.jpg"
                        alt="image"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h3 style={{ color: "#fdcb32" }}>Our Commitments:</h3>
                      <ul className="sx-arrow-circle-checked">
                        <li style={{ color: "white" }}>
                          <strong>Transparent Communication:</strong> We believe in open and honest communication with our clients. We'll keep you informed about the progress of your campaigns and provide regular updates.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Results-Oriented Focus:</strong> Our primary goal is to help you achieve measurable results and increase your ROI.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Customized Strategies:</strong> We'll tailor your Google Ads campaigns to your specific business objectives and target audience.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Continuous Optimization:</strong> We'll continuously monitor and optimize your campaigns to improve performance and reduce costs.
                        </li>
                        <li style={{ color: "white" }}>
                          <strong>Expert Guidance:</strong> Our team of experienced professionals will provide you with expert guidance and support throughout the entire process.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDetailPage6;
