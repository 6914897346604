function SectionTestimonials3() {
    return (
        <>
            <div className="section-full  p-t110 p-b80 sx-about-bx4-outer"  style={{  backgroundColor: "black"}}>
                <div className="container">
                    <div className="section-content">
                        {/*testimonial section */}
                        <div className="sx-testimonial-bx-2  m-b30">
                            {/* TITLE START */}
                            <div className="section-head center">
                                <div className="sx-head-s-title" style={{color:"#fdcb32"}}>Our Testimonials</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title" style={{ color: '#fff' }}>What Clients Say?</h2>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="owl-carousel testimonial-section-3 owl-btn-vertical-center">
                                {/*One block*/}
                                <div className="item">
                                    <div className="testimonial-3  hover-animation-1 sx-bg-light" style={{
                                        minHeight: '450px'
                                    }} >
                                        <div className="testimonial-detail">
                                            <div className="testimonial-user-info">
                                                <span className="testimonial-position" >CEO, Taxi Services Inc.</span>
                                                <h4 className="testimonial-name">Oliver Eliah</h4>
                                            </div>
                                        </div>
                                        <div className="testimonial-text">
                                            <p>I was struggling to get my website noticed online. First Rankerz completely revamped my digital strategy and within a few months, my website traffic skyrocketed. Their team is incredibly knowledgeable and responsive.</p>
                                        </div>
                                        <div className="testimonial-footer">
                                            <div className="testimonial-quote sx-text-primary">
                                                <i className="flaticon-quote" />
                                            </div>
                                            <ul className="sx-rating-st1">
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                            </ul>
                                            <span className="sx-review-count">(5 Reviews)</span>
                                        </div>
                                    </div>
                                </div>
                                {/*One two*/}
                                <div className="item" >
                                    <div className="testimonial-3  hover-animation-1 sx-bg-light" style={{
                                        minHeight: '450px'
                                    }} >
                                        <div className="testimonial-detail">
                                            <div className="testimonial-user-info">
                                                <span className="testimonial-position">CEO, Trucking Company</span>
                                                <h4 className="testimonial-name">Delilah Brooklyn</h4>
                                            </div>
                                        </div>
                                        <div className="testimonial-text">
                                            <p> First Rankerz exceeded my expectations with their SEO services. My website now ranks higher on Google for relevant keywords, and I've seen a significant increase in leads.</p>
                                        </div>
                                        <div className="testimonial-footer">
                                            <div className="testimonial-quote sx-text-primary">
                                                <i className="flaticon-quote" />
                                            </div>
                                            <ul className="sx-rating-st1">
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                            </ul>
                                            <span className="sx-review-count">(5 Reviews)</span>
                                        </div>
                                    </div>
                                </div>
                                {/*One three*/}
                                <div className="item">
                                    <div className="testimonial-3  hover-animation-1 sx-bg-light" style={{
                                        minHeight: '450px'
                                    }} >
                                        <div className="testimonial-detail">
                                            <div className="testimonial-user-info">
                                                <span className="testimonial-position">CEO, NEMT Services</span>
                                                <h4 className="testimonial-name">Emily Davis</h4>
                                            </div>
                                        </div>
                                        <div className="testimonial-text">
                                            <p>I was impressed by the personalized approach taken by First Rankerz. They took the time to understand my business goals and tailored their strategies accordingly. Their team is always available to answer questions and provide support.</p>
                                        </div>
                                        <div className="testimonial-footer">
                                            <div className="testimonial-quote sx-text-primary">
                                                <i className="flaticon-quote" />
                                            </div>
                                            <ul className="sx-rating-st1">
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                            </ul>
                                            <span className="sx-review-count">(5 Reviews)</span>
                                        </div>
                                    </div>
                                </div>
                                {/*One four*/}
                                <div className="item">
                                    <div className="testimonial-3  hover-animation-1 sx-bg-light" style={{
                                        minHeight: '450px'
                                    }} >
                                        <div className="testimonial-detail">
                                            <div className="testimonial-user-info">
                                                <span className="testimonial-position">Founder, Towing Services</span>
                                                <h4 className="testimonial-name">Jane Smith</h4>
                                            </div>
                                        </div>
                                        <div className="testimonial-text">
                                            <p>The website design created by First Rankerz is not only visually stunning but also user-friendly. It has helped improve my brand image and attract more visitors.</p>
                                        </div>
                                        <div className="testimonial-footer">
                                            <div className="testimonial-quote sx-text-primary">
                                                <i className="flaticon-quote" />
                                            </div>
                                            <ul className="sx-rating-st1">
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                                <li><span><i className="fa fa-star" /></span></li>
                                            </ul>
                                            <span className="sx-review-count">(5 Reviews)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionTestimonials3;