import { SectionWhyChooseUsInner } from "../../home/index2/section-why-choose-us";

function SectionWhyChooseUs() {
    return (
        <>
            <div className="section-full  p-t100 p-b0 sx-bg-light sx-about-bx4-outer" style={{backgroundColor:"black"}}>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <SectionWhyChooseUsInner />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionWhyChooseUs;