import { NavLink } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";

function Pricingbox() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-our-services-outer sx-bg-light" style={{  backgroundColor: "black"}}>
                <div className="container" style={{background:"#fdcb32"}}>
                    {/* TITLE START */}
                    <div className="section-head center" >
                     <div className="sx-head-s-title" ><h4 style={{  color: "black"}}>Our Pricing</h4> </div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title" style={{  color: "black"}}>Select Best Plan For Yourself From Here.</h2>
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="sx-our-services-bx">
                            <div className="row">
                               
                                <div className="col-lg-4 col-md-6 d-flex" >
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/seo3.jpg" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte" style={{  color: "black"}}>SEO Packages</h4>
                                                <p>Providing expert SEO services to help businesses improve their online visibility and reach their target audience.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/pricing/SEO-Packages" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/coding.jpg" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte" style={{  color: "black"}}>Website Development CMS Packages</h4>
                                                <p>Creating innovative and user-centric websites to enhance your online presence and drive business growth.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/pricing/Website-Development-CMS-Packages" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/website.jpeg" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte" style={{  color: "black"}}>E-Commerce Websites Development Pacakges</h4>
                                                <p>First Page Rankerz offers comprehensive e-commerce website packages tailored to your business needs.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/pricing/E-Commerce-Websites-Development-Pacakges" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Card container */}
                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/content3.webp" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte" style={{  color: "black"}}>Content Writing Packages</h4>
                                                <p>Crafting compelling content that engages your audience and drives results.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/pricing/Content-Writing-Packages" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                               

                                <div className="col-lg-4 col-md-6 d-flex">
                                    <div className="sx-service-bx-1 flex-grow-1 d-flex flex-column">
                                        <div className="sx-icon-box-wraper flex-grow-1 d-flex flex-column">
                                            <div className="scale-in-center">
                                                <span className="sx-text-primary"><ItodoImage src="images/services/images/social1.jpg" alt="image" style={{ borderRadius: '50%' }} /></span>
                                            </div>
                                            <div className="icon-content flex-grow-1 d-flex flex-column">
                                                <h4 className="sx-tilte" style={{  color: "black"}}>SMO Packages</h4>
                                                <p>Optimizing your social media presence to reach a wider audience and engage with your followers.</p>
                                                <div className="sx-center-btn mt-auto">
                                                    <NavLink to="/pricing/SMO-Packages" className="site-button-circle">
                                                        <i className="fa fa-long-arrow-right" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Pricingbox;
