import ItodoImage from "../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import { NavLink } from "react-router-dom";

function PackagePage1() {
  useEffect(() => {
    loadScript("js/custom.js");
  }, []);

  return (
    <>
      {/* service detail SECTION START */}
      <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white" style={{ backgroundColor: "black" }}>
        <div className="sx-ser-detail-l-pic p-b80">
          <div className="sx-media relative">
            <ItodoImage
              src="images/services/images/seo5.avif"
              alt="image"
              className="service-img"
            />
            <div
              className="sx-bnr-outline-text"
              style={{
                color: "#fff",
                WebkitTextFillColor: "#fff",
                WebkitTextStrokeColor: "transparent",
              }}
            >
              <h1
                style={{
                  color: "#fff",
                  WebkitTextFillColor: "#fff",
                  WebkitTextStrokeColor: "transparent",
                }}
              >
                Search Engine Optimization Packages
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="sx-our-service-detail">
              {/* TITLE START */}
              <div className="section-head2">
                <h1 className="sx-title d-flex justify-content-center" style={{ color: "#fdcb32" }}>SEO Packages</h1>
                <div className="sx-head-l-title">
                  <h3 style={{ color: "#fdcb32" }}>
                    Tailored SEO packages to suit your business needs and budget.
                  </h3>
                </div>
              </div>
              {/* TITLE END */}

              {/* Packages Container */}
              <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Basic SEO <br /> Package</h2>
                    <h1 className="d-flex justify-content-center">$249/-</h1>
                    <h5>First Hand SEO Analysis</h5>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
                      <li><strong>No. of Keywords: 10</strong></li>
                      <li><strong>Hours of work/month: 40</strong></li>
                      <li><strong>Pre-Optimization Website Analysis</strong></li>
                      <li><strong>Competitor Analysis</strong></li>
                      <li><strong>Keyword Research & Analysis</strong></li>
                      <li><strong>Baseline Ranking Check</strong></li>
                      <li><strong>Duplicate Content Check</strong></li>
                      <li><strong>Google Penalty Check</strong></li>
                      <li><strong>Back Link Analysis (If required)</strong></li>
                      <h5>On-Page Optimization Activities</h5>
                      {/* <li><strong>On-Page Optimization Activities</strong></li> */}
                      <li><strong>Website Canonical Check</strong></li>
                      <li><strong>Title Tag Optimization</strong></li>
                      <li><strong>META Tags Optimization</strong></li>
                      <li><strong>Heading Tags Optimization</strong></li>
                      <li><strong>Image Alt Tags Optimization</strong></li>
                      <li><strong>Content Optimization</strong></li>
                      <li><strong>SEO Friendly URL Setup</strong></li>
                      <li><strong>Site Navigation Analysis</strong></li>
                      <li><strong>404 Page Implementation</strong></li>
                      <li><strong>Broken Links Check</strong></li>
                      <li><strong>Website Speed Check</strong></li>
                      <li><strong>Google Indexed Pages Check</strong></li>
                      <li><strong>Robots.txt Creation</strong></li>
                      <li><strong>Google XML Sitemap</strong></li>
                      <li><strong>HTML Sitemap Setup</strong></li>
                      <li><strong>Hyperlink Optimization</strong></li>
                      <li><strong>Website Responsive Check</strong></li>
                      <li><strong>Website Permalinks Analysis</strong></li>
                      <li><strong>Internal Linking Optimization</strong></li>
                      <li><strong>Google Webmasters Tool Setup</strong></li>
                      <li><strong>Bing Webmaster Tools Setup</strong></li>
                      <li><strong>Google Analytics Setup</strong></li>
                      <li><strong>Structured Data Setup</strong></li>
                      <li><strong>On Site Blog Section Creation</strong></li>
                      <h5>Off-Page Optimization Activities</h5>
                      {/* <li><strong>Off-Page Optimization Activities</strong></li> */}
                      <li><strong>Search Engine Submission</strong></li>
                      <li><strong>Guest Blog Writing: 1</strong></li>
                      <li><strong>Guest Blog Links: 1</strong></li>
                      <li><strong>Guest Blog Social Bookmarking Links: 3</strong></li>
                      <li><strong>Article Writing: 1</strong></li>
                      <li><strong>Article Submissions: 1</strong></li>
                      <li><strong>Article Marketing: 5</strong></li>
                      <li><strong>Image sharing: 2</strong></li>
                      <li><strong>Contextual Links</strong></li>
                      <li><strong>Keyword used in anchor text</strong></li>
                      <li><strong>Share Each Blog Post Across Social Media Networks</strong></li>
                      <li><strong>Press Release (If Client Provides News)</strong></li>
                      <li><strong>Press Release Bookmarking Links</strong></li>
                      <li><strong>Social Bookmarking Links: 5</strong></li>
                      <li><strong>Micro Blogging: 1</strong></li>
                      <li><strong>Classified Submissions: 3</strong></li>
                      <li><strong>Infographics Creation in Every 1 Month</strong></li>
                      <li><strong>Infographics Post in Every 1 Month</strong></li>
                      <li><strong>Share each Infographics on Social Media Networks</strong></li>
                      <li><strong>Google Business Page Creation</strong></li>
                      <li><strong>Bing Local Listing Creation</strong></li>
                      <li><strong>PPT Submissions</strong></li>
                      <li><strong>Video Marketing (if Client provides)</strong></li>
                      <li><strong>Location optimization: 1</strong></li>
                      <li><strong>Local Business Listings: 1</strong></li>
                      <li><strong>NAP Syndication</strong></li>
                      <h5>SMO Activities</h5>
                      {/* <li><strong>SMO Activities</strong></li> */}
                      <li><strong>Facebook Profile Creation</strong></li>
                      <li><strong>Facebook Fan Page Creation</strong></li>
                      <li><strong>Facebook Posting & Sharing</strong></li>
                      <li><strong>Twitter Profile Creation</strong></li>
                      <li><strong>Twitter Post</strong></li>
                      <li><strong>Pinterest Account Creation/Management</strong></li>
                      <li><strong>Pinterest Followers</strong></li>
                      <li><strong>Updating of pin boards</strong></li>
                      <li><strong>Pins (If Client Provides Images)</strong></li>
                      <li><strong>Instagram Profile Creation</strong></li>
                      <li><strong>Instagram Posting & Sharing</strong></li>
                      <li><strong>Linkedin Profile Creation</strong></li>
                      <li><strong>Linkedin Posting & Sharing</strong></li>
                      <h5>Reports</h5>
                      {/* <li><strong>Reports</strong></li> */}
                      <li><strong>Monthly Website Analytics Report</strong></li>
                      <li><strong>Monthly Keywords Ranking Report</strong></li>
                      <li><strong>Monthly Off Page Submission Report</strong></li>
                      <h5>Customer Support</h5>
                      {/* <li><strong>Customer Support</strong></li> */}
                      <li><strong>Email</strong></li>
                      <li><strong>Phone</strong></li>
                      <li><strong>Chat</strong></li>
                    </ul>
                    <NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Standard SEO <br /> Package</h2>
                    <h1 className="d-flex justify-content-center">$349/-</h1>
                    <h5>First Hand SEO Analysis</h5>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
                      <li><strong>No. of Keywords: 20</strong></li>
                      <li><strong>Hours of work/month: 60</strong></li>
                      <li><strong>Pre-Optimization Website Analysis</strong></li>
                      <li><strong>Competitor Analysis</strong></li>
                      <li><strong>Keyword Research & Analysis</strong></li>
                      <li><strong>Baseline Ranking Check</strong></li>
                      <li><strong>Duplicate Content Check</strong></li>
                      <li><strong>Google Penalty Check</strong></li>
                      <li><strong>Back Link Analysis (If required)</strong></li>
                      <h5>On-Page Optimization Activities</h5>
                      {/* <li><strong>On-Page Optimization Activities</strong></li> */}
                      <li><strong>Website Canonical Check</strong></li>
                      <li><strong>Title Tag Optimization</strong></li>
                      <li><strong>META Tags Optimization</strong></li>
                      <li><strong>Heading Tags Optimization</strong></li>
                      <li><strong>Image Alt Tags Optimization</strong></li>
                      <li><strong>Content Optimization</strong></li>
                      <li><strong>SEO Friendly URL Setup</strong></li>
                      <li><strong>Site Navigation Analysis</strong></li>
                      <li><strong>404 Page Implementation</strong></li>
                      <li><strong>Broken Links Check</strong></li>
                      <li><strong>Website Speed Check</strong></li>
                      <li><strong>Google Indexed Pages Check</strong></li>
                      <li><strong>Robots.txt Creation</strong></li>
                      <li><strong>Google XML Sitemap</strong></li>
                      <li><strong>HTML Sitemap Setup</strong></li>
                      <li><strong>Hyperlink Optimization</strong></li>
                      <li><strong>Website Responsive Check</strong></li>
                      <li><strong>Website Permalinks Analysis</strong></li>
                      <li><strong>Internal Linking Optimization</strong></li>
                      <li><strong>Google Webmasters Tool Setup</strong></li>
                      <li><strong>Bing Webmaster Tools Setup</strong></li>
                      <li><strong>Google Analytics Setup</strong></li>
                      <li><strong>Structured Data Setup</strong></li>
                      <li><strong>On Site Blog Section Creation</strong></li>
                      <h5>Off-Page Optimization Activities</h5>
                      {/* <li><strong>Off-Page Optimization Activities</strong></li> */}
                      <li><strong>Search Engine Submission</strong></li>
                      <li><strong>Guest Blog Writing: 2</strong></li>
                      <li><strong>Guest Blog Links: 3</strong></li>
                      <li><strong>Guest Blog Social Bookmarking Links: 4</strong></li>
                      <li><strong>Article Writing: 2</strong></li>
                      <li><strong>Article Submissions: 2</strong></li>
                      <li><strong>Article Marketing: 8</strong></li>
                      <li><strong>Image sharing: 4</strong></li>
                      <li><strong>Contextual Links</strong></li>
                      <li><strong>Keyword used in anchor text</strong></li>
                      <li><strong>Share Each Blog Post Across Social Media Networks</strong></li>
                      <li><strong>Press Release (If Client Provides News)</strong></li>
                      <li><strong>Press Release Bookmarking Links</strong></li>
                      <li><strong>Social Bookmarking Links: 10</strong></li>
                      <li><strong>Micro Blogging: 3</strong></li>
                      <li><strong>Classified Submissions: 5</strong></li>
                      <li><strong>Infographics Creation in Every 1 Month</strong></li>
                      <li><strong>Infographics Post in Every 1 Month</strong></li>
                      <li><strong>Share each Infographics on Social Media Networks</strong></li>
                      <li><strong>Google Business Page Creation</strong></li>
                      <li><strong>Bing Local Listing Creation</strong></li>
                      <li><strong>PPT Submissions</strong></li>
                      <li><strong>Video Marketing (if Client provides)</strong></li>
                      <li><strong>Location optimization: 1</strong></li>
                      <li><strong>Local Business Listings: 1</strong></li>
                      <li><strong>NAP Syndication</strong></li>
                      <h5>SMO Activities</h5>
                      {/* <li><strong>SMO Activities</strong></li> */}
                      <li><strong>Facebook Profile Creation</strong></li>
                      <li><strong>Facebook Fan Page Creation</strong></li>
                      <li><strong>Facebook Posting & Sharing: 1</strong></li>
                      <li><strong>Twitter Profile Creation</strong></li>
                      <li><strong>Twitter Post: 1</strong></li>
                      <li><strong>Pinterest Account Creation/Management</strong></li>
                      <li><strong>Pinterest Followers</strong></li>
                      <li><strong>Updating of pin boards</strong></li>
                      <li><strong>Pins (If Client Provides Images)</strong></li>
                      <li><strong>Instagram Profile Creation</strong></li>
                      <li><strong>Instagram Posting & Sharing</strong></li>
                      <li><strong>Linkedin Profile Creation</strong></li>
                      <li><strong>Linkedin Posting & Sharing</strong></li>
                      <h5>Reports</h5>
                      {/* <li><strong>Reports</strong></li> */}
                      <li><strong>Monthly Website Analytics Report</strong></li>
                      <li><strong>Monthly Keywords Ranking Report</strong></li>
                      <li><strong>Monthly Off Page Submission Report</strong></li>
                      <h5>Customer Support</h5>
                      {/* <li><strong>Customer Support</strong></li> */}
                      <li><strong>Email</strong></li>
                      <li><strong>Phone</strong></li>
                      <li><strong>Chat</strong></li>
                    </ul>
                    <NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="sx-about-bx4-content" style={{ backgroundColor: "#d4a107", borderRadius: "5px", padding: "20px" }}>
                    <h2 className="d-flex justify-content-center">Professional <br /> SEO Package</h2>
                    <h1 className="d-flex justify-content-center">$499/-</h1>
                    <h5>First Hand SEO Analysis</h5>
                    <ul className="sx-arrow-circle-checked d-grid justify-content-center">
                      <li><strong>No. of Keywords: 30</strong></li>
                      <li><strong>Hours of work/month: 80</strong></li>
                      <li><strong>Pre-Optimization Website Analysis</strong></li>
                      <li><strong>Competitor Analysis</strong></li>
                      <li><strong>Keyword Research & Analysis</strong></li>
                      <li><strong>Baseline Ranking Check</strong></li>
                      <li><strong>Duplicate Content Check</strong></li>
                      <li><strong>Google Penalty Check</strong></li>
                      <li><strong>Back Link Analysis (If required)</strong></li>
                      <h5>On-Page Optimization Activities</h5>
                      {/* <li><strong>On-Page Optimization Activities</strong></li> */}
                      <li><strong>Website Canonical Check</strong></li>
                      <li><strong>Title Tag Optimization</strong></li>
                      <li><strong>META Tags Optimization</strong></li>
                      <li><strong>Heading Tags Optimization</strong></li>
                      <li><strong>Image Alt Tags Optimization</strong></li>
                      <li><strong>Content Optimization</strong></li>
                      <li><strong>SEO Friendly URL Setup</strong></li>
                      <li><strong>Site Navigation Analysis</strong></li>
                      <li><strong>404 Page Implementation</strong></li>
                      <li><strong>Broken Links Check</strong></li>
                      <li><strong>Website Speed Check</strong></li>
                      <li><strong>Google Indexed Pages Check</strong></li>
                      <li><strong>Robots.txt Creation</strong></li>
                      <li><strong>Google XML Sitemap</strong></li>
                      <li><strong>HTML Sitemap Setup</strong></li>
                      <li><strong>Hyperlink Optimization</strong></li>
                      <li><strong>Website Responsive Check</strong></li>
                      <li><strong>Website Permalinks Analysis</strong></li>
                      <li><strong>Internal Linking Optimization</strong></li>
                      <li><strong>Google Webmasters Tool Setup</strong></li>
                      <li><strong>Bing Webmaster Tools Setup</strong></li>
                      <li><strong>Google Analytics Setup</strong></li>
                      <li><strong>Structured Data Setup</strong></li>
                      <li><strong>On Site Blog Section Creation</strong></li>
                      <h5>Off-Page Optimization Activities</h5>
                      {/* <li><strong>Off-Page Optimization Activities</strong></li> */}
                      <li><strong>Search Engine Submission</strong></li>
                      <li><strong>Guest Blog Writing: 3</strong></li>
                      <li><strong>Guest Blog Links: 5</strong></li>
                      <li><strong>Guest Blog Social Bookmarking Links: 6</strong></li>
                      <li><strong>Article Writing: 3</strong></li>
                      <li><strong>Article Submissions: 4</strong></li>
                      <li><strong>Article Marketing: 12</strong></li>
                      <li><strong>Image sharing: 6</strong></li>
                      <li><strong>Contextual Links</strong></li>
                      <li><strong>Keyword used in anchor text</strong></li>
                      <li><strong>Share Each Blog Post Across Social Media Networks</strong></li>
                      <li><strong>Press Release (If Client Provides News)</strong></li>
                      <li><strong>Press Release Bookmarking Links</strong></li>
                      <li><strong>Social Bookmarking Links: 15</strong></li>
                      <li><strong>Micro Blogging: 5</strong></li>
                      <li><strong>Classified Submissions: 7</strong></li>
                      <li><strong>Infographics Creation in Every 1 Month</strong></li>
                      <li><strong>Infographics Post in Every 1 Month</strong></li>
                      <li><strong>Share each Infographics on Social Media Networks</strong></li>
                      <li><strong>Google Business Page Creation</strong></li>
                      <li><strong>Bing Local Listing Creation</strong></li>
                      <li><strong>PPT Submissions: 1</strong></li>
                      <li><strong>Video Marketing (if Client provides)</strong></li>
                      <li><strong>Location optimization: 2</strong></li>
                      <li><strong>Local Business Listings: 2</strong></li>
                      <li><strong>NAP Syndication</strong></li>
                      <h5>SMO Activities</h5>
                      {/* <li><strong>SMO Activities</strong></li> */}
                      <li><strong>Facebook Profile Creation</strong></li>
                      <li><strong>Facebook Fan Page Creation</strong></li>
                      <li><strong>Facebook Posting & Sharing: 4</strong></li>
                      <li><strong>Twitter Profile Creation</strong></li>
                      <li><strong>Twitter Post: 4</strong></li>
                      <li><strong>Pinterest Account Creation/Management</strong></li>
                      <li><strong>Pinterest Followers</strong></li>
                      <li><strong>Updating of pin boards: 2</strong></li>
                      <li><strong>Pins (If Client Provides Images)</strong></li>
                      <li><strong>Instagram Profile Creation</strong></li>
                      <li><strong>Instagram Posting & Sharing</strong></li>
                      <li><strong>Linkedin Profile Creation</strong></li>
                      <li><strong>Linkedin Posting & Sharing</strong></li>
                      <h5>Reports</h5>
                      {/* <li><strong>Reports</strong></li> */}
                      <li><strong>Monthly Website Analytics Report</strong></li>
                      <li><strong>Monthly Keywords Ranking Report</strong></li>
                      <li><strong>Monthly Off Page Submission Report</strong></li>
                      <h5>Customer Support</h5>
                      {/* <li><strong>Customer Support</strong></li> */}
                      <li><strong>Email</strong></li>
                      <li><strong>Phone</strong></li>
                      <li><strong>Chat</strong></li>
                    </ul>
                    <NavLink to="/contact-us" className="site-button sx-btn-primary icon sx-btn-lg" style={{ backgroundColor: "black", color: "#fdcb32" }}>
                      <i className="fa fa-long-arrow-right" />
                      Contact Us
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackagePage1;
