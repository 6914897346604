import { NavLink } from "react-router-dom";
import ItodoImage from "../../../elements/itodo-img";
import "./SectionServices3.css"; // Import the CSS file

function SectionServices3() {
    return (
        <>
            <div className="section-full p-t50 p-b5 sx-why-choose2-outer sx-bg-light" style={{  backgroundColor: "black"}}>
                <div className="container">
                    <div className="large-title-block sx-all-white">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="section-head left">
                                    <div className="sx-head-s-title" style={{color:"#fdcb32"}}>Our Services</div>
                                    <div className="sx-head-l-title">
                                        <h2 className="sx-title">What We Are Expert At Our Special Services.</h2>
                                    </div>
                                </div>
                            </div>

                            {/* extra */}
                            {/* Our goal is to help our clients increase their online visibility, attract more traffic, and generate leads. We believe in building long-term partnerships with our clients and delivering results that exceed their expectations. */}


                            <div className="col-lg-6 col-md-6">
                                <div className="large-title-info">
                                    <p style={{color:"white"}}>First Rankerz is a leading digital marketing agency that specializes in helping businesses achieve their online goals. With a team of experienced professionals, we offer a comprehensive range of services including Website Design and Development, Search Engine Optimization (SEO), Social Media Optimization (SMO), Content Writing and Google Ads management. 
                                       
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-content" style={{ color: '#000' }}>
                        <div className="owl-carousel sx-why-choose-carousel2 m-b10">
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/seo5.png" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Search-Engine-Optimization-Services" className="sx-why-ch-category">SEARCH ENGINE OPTIMIZATION</NavLink>
                                            <NavLink to="/services/Search-Engine-Optimization-Services"><h4 className="sx-tilte">Search Engine Optimization Services</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/coding.jpg" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Website-Development-Services" className="sx-why-ch-category">WEBSITE DEVELOPMENT</NavLink>
                                            <NavLink to="/services/Website-Development-Services"><h4 className="sx-tilte">WEBSITE DESIGN AND DEVELOPMENT SERVICES</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/designing.jpg" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Graphic-Designing-Services" className="sx-why-ch-category">GRAPHIC DESIGNING</NavLink>
                                            <NavLink to="/services/Graphic-Designing-Services"><h4 className="sx-tilte">Graphic Designing Services</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/google.png" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Google-Ads-Services" className="sx-why-ch-category">GOOGLE ADS</NavLink>
                                            <NavLink to="/services/Google-Ads-Services"><h4 className="sx-tilte">Google Ads Services</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/web development.webp" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Website-Development-Services" className="sx-why-ch-category">Website Development </NavLink>
                                            <NavLink to="/services/Website-Development-Services"><h4 className="sx-tilte">Website Development Services</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/social1.jpg" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Social-Media-Optimization-Services" className="sx-why-ch-category">Social Media Optimization </NavLink>
                                            <NavLink to="/services/Social-Media-Optimization-Services"><h4 className="sx-tilte">Social Media Optimization Services</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/content4.avif" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Content-Writing-Services" className="sx-why-ch-category">Content Writing</NavLink>
                                            <NavLink to="/services/Content-Writing-Services"><h4 className="sx-tilte">Content Writing Services</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="sx-service-bx-2">
                                    <div className="sx-icon-box-wraper">
                                        <div className="sx-img-bx">
                                            <span className="sx-img-bx-media">
                                                <ItodoImage src="images/services/images/social1.jpg" alt="image" className="service-image" />
                                            </span>
                                        </div>
                                        <div className="icon-content">
                                            <NavLink to="/services/Social-Media-Optimization-Services" className="sx-why-ch-category">Social Media Optimization </NavLink>
                                            <NavLink to="/services/Social-Media-Optimization-Services"><h4 className="sx-tilte">Social Media Optimization Services</h4></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionServices3;
